import React, { useState, useEffect } from 'react';
import './App.css';
import axios from 'axios';
import { BrowserRouter as Router, Routes, Route, Link } from 'react-router-dom';
import Report from './routes/Report';
import Login from './routes/Login';
import Feed from './routes/Feed';
import Profile from './routes/Profile';
import SignUp from './routes/SignUp';
import VerifyPhoneNumber from './routes/VerifyPhoneNumber';
import VerifyPhoneNumberLogin from './routes/VerifyPhoneNumberLogin';
import EnterName from './routes/EnterName';
import Gender from './routes/gender';
import DateOfBirth from './routes/DateOfBirth';
import Ethnicity from './routes/Ethnicity';
import SelectState from './routes/SelectState';
import SelectInterests from './routes/SelectInterests';
import Payment from './routes/payment';
import EmailAddress from './routes/EmailAddress';
import UploadProfilePicture from './routes/UploadProfilePicture';
import PaymentPaypal from './routes/paymentpaypal';
import PaymentVenmo from './routes/paymentvenmo';
import TermsAndConditions from './routes/TermsAndConditions'
import BrandCreate from './routes/BrandCreate';
import AssetCreate from './routes/AssetCreate';
import useToken from './components/useToken';
import PrivateRoute from './components/PrivateRoute';
import FeedConstruction from './routes/FeedConstruction';
import EncodeDecode from './routes/encodedecode';
import AmbassadorProfiles from './routes/AmbassadorProfiles';
import FeedSessionExport from './routes/FeedSessionExport';
import logo from './assets/images/icon-wordmark_ambassco.png';
import ThankYouPage from './routes/thankYouPage';

function App() {
  
  const [message, setMessage] = useState('❌');
  const [loading, setLoading] = useState(true);

  const { token, removeToken, setToken } = useToken();


  useEffect(() => {
    axios.get('https://ambassco-staging.feeltiptop.com/api')
      .then((response) => {
        setMessage(response.data.message);
        setLoading(false);
      })
      .catch((error) => {
        console.error(error);
        setLoading(false);
      });
  }, []);

  return (
    <Router>
      <div className="App">
        <img src={logo} alt="Ambassco Logo" className="logo" loading="lazy"/>
        <Routes>
          <Route path="/login" element={<Login />} />
          <Route path="/signup" element={<SignUp />} />
          <Route path="/verifyphonenumber" element={<VerifyPhoneNumber setToken={setToken} token={token} removeToken={removeToken}/>} />
          <Route path="/verifyphonenumberLogin" element={<VerifyPhoneNumberLogin setToken={setToken} token={token} removeToken={removeToken}/>} />
          <Route path="/entername" element={<PrivateRoute token={token}><EnterName /></PrivateRoute>} />
          <Route path="/gender" element={<PrivateRoute token={token}><Gender /></PrivateRoute>} />
          <Route path="/DateOfBirth" element={<PrivateRoute token={token}><DateOfBirth /></PrivateRoute>} />
          <Route path="/Ethnicity" element={<PrivateRoute token={token}><Ethnicity /></PrivateRoute>} />
          <Route path="/SelectState" element={<PrivateRoute token={token}><SelectState /></PrivateRoute>} />
          <Route path="/SelectInterests" element={<PrivateRoute token={token}><SelectInterests /></PrivateRoute>} />
          <Route path="/EmailAddress" element={<PrivateRoute token={token}><EmailAddress /></PrivateRoute>} />
          <Route path="/UploadProfilePicture" element={<PrivateRoute token={token}><UploadProfilePicture /></PrivateRoute>} />
          <Route path="/payment" element={<PrivateRoute token={token}><Payment /></PrivateRoute>} />
          <Route path="/paymentpaypal" element={<PrivateRoute token={token}><PaymentPaypal setToken={setToken} token={token} removeToken={removeToken}/></PrivateRoute>} />
          <Route path="/paymentvenmo" element={<PrivateRoute token={token}><PaymentVenmo setToken={setToken} token={token} removeToken={removeToken}/></PrivateRoute>} />
          <Route path="/TermsAndConditions" element={<PrivateRoute token={token}><TermsAndConditions setToken={setToken} token={token} removeToken={removeToken}/></PrivateRoute>} />
          <Route path="/feed" element={<PrivateRoute token={token}><Feed setToken={setToken} token={token} removeToken={removeToken}/></PrivateRoute>} />
          <Route path="/profile" element={<Profile />} />
          <Route path="/apply" element={() => <div>Apply Page</div>} />
          <Route path="/report" element={<Report />}/>
          <Route path="/brandcreate" element={<PrivateRoute token={token}><BrandCreate setToken={setToken} token={token} removeToken={removeToken}/></PrivateRoute>} />
          <Route path="/assetcreate" element={<PrivateRoute token={token}><AssetCreate setToken={setToken} token={token} removeToken={removeToken}/></PrivateRoute>} />
          <Route path="/FeedConstruction" element={<PrivateRoute token={token}><FeedConstruction setToken={setToken} token={token} removeToken={removeToken}/></PrivateRoute>} />
          <Route path="/EncDec" element={<EncodeDecode/>} />
          <Route path="/AmbassadorProfiles" element={<AmbassadorProfiles/>} /> 
          <Route path="/FeedSessionExport" element={<FeedSessionExport/>} /> 
          <Route path="/thankyou" element={<ThankYouPage/>} /> 
          <Route path="*" element={() => <div>404 Not Found</div>} /> {/* Fallback page for undefined routes */}
        </Routes>
      </div>
    </Router>
  );
}

export default App;