import React, { useState, useEffect } from 'react';
import Select from 'react-select';

import axios from 'axios';

import logo from '../assets/images/blacknoback.png';
import '../assetFormStyle.css'

function AssetCreate(props) {
    // Create States
    const [assetName, setAssetName] = useState('');
    const [assetType, setAssetType] = useState('');
    const [caption, setCaption] = useState('');
    const [media, setMedia] = useState('');
    const [url, setUrl] = useState('');
    const [brandId, setBrandId] = useState('');
    const [allBrands, setAllBrands] = useState([]);
    const [setting, setSettings] = useState('');
    // Default set to public
    const [error, setError] = useState(0);

    useEffect(() => {
        // get all brands
        axios
          .get("https://ambassco-staging.feeltiptop.com/api/getBrands",{
            headers: {
              'Authorization': 'Bearer ' + props.token
            }})
          .then((response) => {
            // setBrands(response.data.map(opt =>({label: opt[1], value: opt[0]})));
            let tmpbrands = response.data.map(opt =>({label: opt[1], value: opt[0]}))
            // setBrands([{label: "b1", value: 0}]);
            setAllBrands(tmpbrands);
            console.log("brands",tmpbrands);
          })
          .catch((error) => {
            // Handle the error
            console.error(error);
          });
      }, []);

    const handleAssetTypeChange = (assetTypeEvent) => {
        const type = assetTypeEvent.target.value;
        setAssetType(type);
    };

    const handleCaptionChange = (captionEvent) => {
        const text = captionEvent.target.value;
        setCaption(text);
    };

    // Checks if valid media input
    const isValidFileType = (filename) => {
        return /\.(jpg|jpeg|png|gif|tiff|mp4|ogg)$/i.test(filename);
    };
    
    // Handles media file input; sets media and error state accordingly
    const handleMediaChange = (mediaFileEvent) => {
        const file = mediaFileEvent.target.files[0];
        if (!assetType) {
            setError('Please choose an Asset Type before selecting media.');
            return;
        }
        const allowedTypes = assetType === 'image' ? ['image/jpeg', 'image/png', 'image/gif', 'image/tiff'] : ['video/mp4', 'video/ogg'];
        if (file && allowedTypes.includes(file.type)) {
            setError(''); // Clear any previous error messages
            const reader = new FileReader();
            reader.onload = (mediaFileEvent) => {
                setMedia(mediaFileEvent.target.result);
            };
            reader.readAsDataURL(file);
        } else {
            setError('Invalid file type. Please select a valid media file.');
            setMedia(null); // Clear the media if the file type is invalid
        }
    };

    const handleUrlChange = (urlEvent) => {
        const urlValue = urlEvent.target.value;
        setUrl(urlValue);
    };

    const handleAssetNameChange = (assetNameEvent) => {
        const assetNameValue = assetNameEvent.target.value;
        setAssetName(assetNameValue);
    };

    const handleBrandChange = (brandEvent) => {
        const brandValue = brandEvent.value;
        setBrandId(brandValue);
    };

    const handleRadioChange = (radioEvent) => {
        const radioValue = radioEvent.target.value;
        setSettings(radioValue);
    };

    const handleSubmit = (submitEvent) => {
        // Prevent Default Behavior
        submitEvent.preventDefault();

        // Create assetData Object
        const assetData = {
            AssetName: assetName,
            AssetType: assetType,
            Caption: caption,
            BrandID: brandId,
            MediaLink: media,
            OpenLink: url,
            BrandAnonymous: setting,
        };
        if (setting === '') {
            setError('Need to select anonymous/public for asset');
            return; 
        }
        // Log form data to Console
        console.log('Form Data:', assetData);

        // Send HTTP POST request to server
        axios
            .post('https://ambassco-staging.feeltiptop.com/api/addAsset', assetData,{
                headers: {
                  'Authorization': 'Bearer ' + props.token
                }})
            .then((response) => {
                console.log('Asset created successfully:', response.data);
                alert('Asset created succesfully!')
                // Handle success
            })
            .catch((error) => {
                console.error('Error creating Asset:', error);

                if (error.response) {
                    // Access the error response data
                    const errorMessage = error.response.data.message;
                    alert('Error creating Asset: ' + errorMessage);
                } else {
                    // If there is no error response, use the default error message
                    alert('Error creating Asset: ' + error.message);
                }
                // Handle error
            });
        
    };
    const handleReset = () => {
        setAssetName('');
        setAssetType('');
        setCaption('');
        setMedia('');
        setUrl('');
        setBrandId('');
        setSettings('');
        setError('');
    };

    return (

    <div className="AssetForm">

    <header className="AssetForm-header">
        <img src={logo} className="Ambassco-logo" alt="logo" />
        <div className="formTitle">Asset Generation Form</div>
    </header>

    <form>
         {/* Input Asset Name */}
        <label htmlFor="assetName">Asset Name:</label>
            <input
            type="text"
            id="assetname"
            name="assetname"
            onChange={handleAssetNameChange}
            required
        />
        <br />
        {/* Input Asset Type */}
        <label htmlFor="assetType">Select Asset Type:</label>
        <select
            id="assetType"
            name="assetType"
            onChange={handleAssetTypeChange}
        >
            <option value="Default">Select Type..</option>
            <option value="image">image</option>
            <option value="video">video</option>
        </select>
        <br />

        {/* Input Caption */}
        <label htmlFor="caption">Caption:</label>
        <textarea 
            id="caption"
            name="caption"
            onChange={handleCaptionChange}
        />
        <br />

        {/* Input Media File */}
        {/* Displays image if input is valid, 'Select Media File:' if invalid */}
       
        <label htmlFor="mediaFile">
            {media ? <img src={media} alt="Media File" className="mediaInput" /> : 'Select Media File:'}
        </label>
        <input
            type="file"
            id="mediaFile"
            name="mediaFile"
            accept={assetType === 'image' ? "image/jpeg, image/png, image/gif, image/tiff" : "video/mp4, video/ogg"}
            onChange={handleMediaChange}
        />
        <br />
        {/* Error Handling */}
        {error && (
            <div className="error-container">
                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                    <path d="M12 22C17.5228 22 22 17.5228 22 12C22 6.47715 17.5228 2 12 2C6.47715 2 2 6.47715 2 12C2 17.5228 6.47715 22 12 22Z" stroke="#DB4335" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                    <path d="M12 8V12" stroke="#DB4335" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                    <path d="M12 16H12.01" stroke="#DB4335" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                </svg>
                <p className='error'>{error}</p>
            </div>
        )}

        {/* Input Website URL */}
        <label htmlFor="url">Website Link:</label>
        <input
            type="url"
            id="url"
            name="url"
            onChange={handleUrlChange}
            required
        />
        <br />

        {/* Input Brand */}
        <label htmlFor="brand">Select Brand:</label>
        <Select options={allBrands}
       
            onChange={handleBrandChange}
        >   
        </Select>
        <br />

        {/* Input Asset Settings; Anonymous: 0 for False, 1 for True */}
        <label>Asset Settings:</label>
        <div className="radios">
            <input
                type="radio"
                id="public"
                name="assetSetting"
                value={0}
                onClick={handleRadioChange}
            />
            <label htmlFor="public">Public</label>
        </div>
        <div className="radioToggle">
            <input
                type="radio"
                id="anonymous"
                name="assetSetting"
                value={1}
                onClick={handleRadioChange}
            />
            <label htmlFor="anonymous">Anonymous</label>
        </div>
        <br />

        {/* To Reset Asset Generation Form */}
        <input type="reset" 
            onClick={handleReset}/>
        <br />

        {/* To Submit Asset Generation Form */}
        <input
            type="submit"
            value="Submit"
            onClick={handleSubmit}
        />

    </form>

    </div>

    );
}

export default AssetCreate;
