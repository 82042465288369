import React, { useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import backArrow from '../assets/images/arrow_back.svg';
import axios from 'axios';
import '../App.css'

function Agreement(props) {
  const [accept, setAccept] = useState('');
  const [consent, setConsent] = useState('');
  const [buttonClicked, setButtonClicked] = useState(false);

  const navigate = useNavigate();
  const { state } = useLocation();
  const { phoneNumber, firstName, lastName, gender, dob, ethnicity, selectedState, interests, email, image, paymentType, paymentInfo} = state;

  const handleBackArrow = () => {
    navigate('/payment', {state: {phoneNumber, firstName, lastName, gender, dob, ethnicity, selectedState, interests, email, image}});
  };

  const sendPaymentInfo = (paymentType) => {
    const payload = {
      phoneNumber,
      firstName,
      lastName,
      gender,
      dob,
      ethnicity,
      selectedState,
      interests,
      email,
      image,
      paymentType,
      paymentInfo,
      termsAndConditions: accept,
      textMessaging: consent
    };

    console.log(payload);

    axios.post('https://ambassco-staging.feeltiptop.com/api/addUser', payload, {
      headers: {
        'Authorization': 'Bearer ' + props.token
      }})
      .then(response => {
        console.log('Success:', response.data);
        console.log(paymentType)
        response.data.access_token && props.setToken(response.data.access_token)
        navigate('/thankyou', {state: {text: 'for signing up!'}})
      })
      .catch(error => {
        setButtonClicked(false);
        alert("Unable to sign up - Error  " + error)
        console.error('Error:', error);
      });
  };

  const handleSubmit = () => {
    if (!buttonClicked) {
      setButtonClicked(true);
      sendPaymentInfo(paymentType)
    }
  };

  const handleAcceptChange = () => {
    if (accept === 'Accepted') {
        setAccept('');
    } else {
        setAccept('Accepted');
    }
  };

  const handleConsentChange = () => {
    if (consent === 'Consented') {
        setConsent('');
    } else {
        setConsent('Consented');
    }
  };

  const isAcceptConsent = () => {
    if (accept === 'Accepted' && consent === 'Consented') {
        return true;
    } else {
        return false;
    }
  };

  return (
    <div className="signup-container">
      <div className="arrow-button">
          <img src={backArrow} alt="Logo" className="arrow" onClick={handleBackArrow}/>
      </div>
      <div className="Terms">
        <div className="termstitle">
            {/* <h2 className='title-verification3'>Please read our</h2> */}
            <a className="title-verification3" href="https://ambassco.com/static/Terms%20of%20Service%20&%20Privacy%20Policy-3cde36d487880a6af07983770cb05c4e.pdf" target="_blank" rel="noopener noreferrer">Please read our <span className="custom-link">Terms and Conditions:</span></a>
        </div>
        <div className="Conditions">
            <div className="Condition">
                <label>
                    <input
                        type='Checkbox'
                        className="TermsCheckbox"
                        id="Accept"
                        onChange={handleAcceptChange}
                    />
                    <span class="custom-checkbox"></span>
                    <div className="Message">I accept the Terms and Conditions</div>
                </label>
            </div>
            <div className="Condition">
                <label>
                    <input
                        type='Checkbox'
                        className="TermsCheckbox"
                        id="Consent"
                        onChange={handleConsentChange}
                    />
                    <span class="custom-checkbox"></span>
                    <div className="Message">I consent to receiving text messages from AmbassCo. <span>&#40;</span>We will only text you regarding login codes and project inquiries. You can reply STOP to opt-out at any time.<span>&#41;</span></div>
                </label>
            </div>
        </div>
      </div>

      <button
        className={`button ${isAcceptConsent() || buttonClicked ? 'red-background' : 'grey-background'}`}
        disabled={isAcceptConsent()===false || buttonClicked}
        onClick={handleSubmit}
      >
        Submit
      </button>
    </div>
  );
}

export default Agreement;