import React, { useState, useEffect } from 'react';
import Select from 'react-select';

import axios from 'axios';
import { CSVLink, CSVDownload } from "react-csv";

import { useSearchParams } from 'react-router-dom';

function FeedSessionExport(props) {
    // Create States
    const [feedsessiondata, setFeedsessiondata] = useState([]);
    const[csvfile, setCsvfile] = useState("FeedSessionExport-" + new Date().toLocaleString() + ".csv")
    // Default set to public
    const [error, setError] = useState(0);
    const [searchParams] = useSearchParams();

    var feedID = searchParams.get("feedid");

    useEffect(() => {
        // get all brands
        axios
          .get("https://ambassco-staging.feeltiptop.com/api/getFeedSessionDump?feedid="+feedID,
        //   {
        //     headers: {
        //       'Authorization': 'Bearer ' + props.token
        //     }}
            )
          .then((response) => {
            setFeedsessiondata(response.data);
            console.log("Feedsessiondata",response);
          })
          .catch((error) => {
            // Handle the error
            console.error(error);
          });
      }, []);

      // function handleExport() {
      //   const csvExporter = new ExportToCsv();
      //   csvExporter.generateCsv(ambprofiles);
      // }
    
      return (
        <div>
         <CSVLink data={feedsessiondata} filename={csvfile} target="_blank"> Download</CSVLink>
        </div>
      );
}

export default FeedSessionExport;
