import React, { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import backArrow from '../assets/images/arrow_back.svg';
import axios from 'axios';
import '../App.css';
import { useParams, useSearchParams } from 'react-router-dom';
import {decryptfromHEX} from './encodedecode.js';
import {SECRETPW} from '../useGlobals.js';

function formatPhoneNumber(inputValue) {
  const cleaned = inputValue.replace(/\D/g, '');
  const match = cleaned.match(/(\d{0,3})(\d{0,3})(\d{0,4})/);
  
  if (match) {
    return match.slice(1, 4).filter(Boolean).join('-');
  } else {
    return '';
  }
}

function Login() {
  const [phoneNumber, setPhoneNumber] = useState('');
  const [error, setError] = useState('');
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const [buttonClicked, setButtonClicked] = useState(false);
  // const [feedID, setFeedID] = useState()
  let feedID = -1;

  document.title = "Ambassco Sign In"; 
  const handleLogin = () => {
    if (!buttonClicked) {
        setButtonClicked(true);
        // Un-comment the line below if you want to test website without backend (Ex: locally on phone)
        // navigate('/verifyphonenumber', { state: { phoneNumber: phoneNumber, initialCode: '000000'} })
        if(searchParams.has("p")){
          let encjson = searchParams.get("p");
          let jsondatastr  = decryptfromHEX(encjson);
          let jsondata
          try {
            jsondata = JSON.parse(jsondatastr);
            feedID = jsondata.feedID; 
          } catch (error) {
            setError("It seems there's an issue with the feed link. Please check the formatting and try again.")
          }
          // console.log(jsondata)
          
          console.log(feedID)
          // console.log(feedID)
            // brandID = jsondata.brandID; 
        // console.log(jsondata, jsondata.feedID, feedID);
      }
      // console.log("Link Feed ID: " + feedID);
      axios.post('https://ambassco-staging.feeltiptop.com/api/checkPhoneNumber', {phoneNumber: phoneNumber})
      .then((response) => {
        if (response.data.exists) {
          if (feedID !== -1 || !searchParams.has("p")) {
            //👇🏻 Send the SMS by calling the backend
            axios.post('https://ambassco-staging.feeltiptop.com/api/phoneVerification', {phoneNumber: phoneNumber})
            .then((response) => {
              navigate('/verifyphonenumberLogin', { state: { phoneNumber: phoneNumber, initialCode: response.data.code, feedID: feedID} });
            })
          }
          
        } else {
          setButtonClicked(false);
          setError("We don't have an account associated with this number")
        }
      })
      .catch((error) => {
        console.error(error);
      });
    }
  };

  const handleKeyPress = (e) => {
    if (e.key === 'Enter' && phoneNumber.length === 12) {
      handleLogin();
    }
  };

  return (
    <div className="signup-container">
      <div className="arrow-button">
        <Link to="/login">
          <img src={backArrow} alt="Logo" className="arrow"/>
        </Link>
      </div>
      <h2 className="title-signup">Enter phone number</h2>

      <input 
        type="tel"
        placeholder="123-456-7890"
        className="input-field"
        value={phoneNumber}
        onChange={(e) => {
          const formattedPhoneNumber = formatPhoneNumber(e.target.value);
          setPhoneNumber(formattedPhoneNumber);
        }}
        onKeyUp={handleKeyPress}
      />

      {error && (
        <div className="error-container2">
          <div className="error-container">
            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
              <path d="M12 22C17.5228 22 22 17.5228 22 12C22 6.47715 17.5228 2 12 2C6.47715 2 2 6.47715 2 12C2 17.5228 6.47715 22 12 22Z" stroke="#DB4335" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
              <path d="M12 8V12" stroke="#DB4335" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
              <path d="M12 16H12.01" stroke="#DB4335" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
            </svg>
            <p className='error'>{error}</p>
          </div>
          <div></div>
          <div className="error-container2">
            <p className="smallCentered-text">Not an Ambassador yet?</p>
            <Link to="/signup" className="apply">Apply <span className="highlight">here</span></Link>
        </div>
        </div>
        
      )}

      <button
        className={`button ${phoneNumber.length !== 12 || buttonClicked ? 'grey-background' : 'red-background'}`}
        disabled={phoneNumber.length !== 12 || buttonClicked}
        onClick={handleLogin}
      >
        Login
      </button>
    </div>
  );
}

export default Login;
