import React, { useState, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import backArrow from '../assets/images/arrow_back.svg';
import '../App.css'
import axios from 'axios';

function Verification(props) {
  const [code, setCode] = useState('');
  const [error, setError] = useState('');
  const [resendAvailable, setResendAvailable] = useState(true);
  const [resendRefreshTime, setresendRefreshTime] = useState(30);
  const navigate = useNavigate();

  const { state } = useLocation();
  const { phoneNumber, initialCode} = state;
  const [veriCode, setVeriCode] = useState(initialCode);

  const getAccessToken = () => {

    axios.post('https://ambassco-staging.feeltiptop.com/api/token', {passCode: '4zPoQ5KAkR'})
      .then(response => {
        props.setToken(response.data.access_token)
      })
      .catch(error => {
        console.error('Error:', error);
      });
  }

  const handleBackToSignUp = () => {
    navigate('/signup');
  };

  const handleInputChange = (value) => {
    const re = /^[0-9\b]+$/;

    // if value is not blank, then test the regex

    if ((value === '' || re.test(value)) &&  value.length <= 6) {
       setCode(value);
       setError('');
    }
  };

  useEffect(() => {
    if (!resendAvailable && resendRefreshTime > 0) {
        const timer = resendRefreshTime > 0 && setInterval(() => setresendRefreshTime(resendRefreshTime - 1), 1000);
        return () => clearInterval(timer);
    } else{
      setResendAvailable(true);
      setresendRefreshTime(30);
    }
  }, [resendRefreshTime, resendAvailable]);

  const handleResetCode = () => {
    axios.post('https://ambassco-staging.feeltiptop.com/api/phoneVerification', {phoneNumber:phoneNumber})
    .then((response) => {
      setVeriCode(response.data.code);
      setResendAvailable(false);
    })
    .catch((error) => {
      console.error(error);
    });

  }

  const handleSubmit = () => {
    
    if (code === veriCode) {

      axios.post('https://ambassco-staging.feeltiptop.com/api/token', {passCode: '4zPoQ5KAkR'})
      .then(response => {
        props.setToken(response.data.access_token)
        navigate('/entername', { state: { phoneNumber: phoneNumber }});
      })
      .catch(error => {
        console.error('Error:', error);
      });
    } else {
      setError('Oops! The code you entered is incorrect.');
    }
  };

  return (
    <div className="signup-container">
      <div className="arrow-button">
          <img src={backArrow} alt="Logo" className="arrow" onClick={handleBackToSignUp} />
      </div>
      <h2 className="title-verification">Enter code</h2>

      <p className="phoneVerificationInstructions">
        Please enter the 6-digit verification code we sent to {phoneNumber}.
      </p>

      <div>
          <input
            style={{textAlign: 'center', letterSpacing:'8px'}}
            type="text"
            inputMode="numeric"
            className={`input-field`}
            value={code}
            onChange={(e) => handleInputChange(e.target.value)}
          />
      </div>
      {error && (
        <div className="error-container">
          <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
            <path d="M12 22C17.5228 22 22 17.5228 22 12C22 6.47715 17.5228 2 12 2C6.47715 2 2 6.47715 2 12C2 17.5228 6.47715 22 12 22Z" stroke="#DB4335" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M12 8V12" stroke="#DB4335" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M12 16H12.01" stroke="#DB4335" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
          </svg>
          <p className='error'>{error}</p>
        </div>
      )}
      <div>
        {resendAvailable 
        ? <p className='resend-code' onClick={() => handleResetCode()}>
            Resend Code
          </p> 
        : null
        }
      </div>
      <div> 
        { resendAvailable 
        ? null 
        : <div className='resend-refresh'>
            <p>&#x2713; Code resent</p>
            <p>Resend code available in {resendRefreshTime} seconds</p>
          </div>
        }
      </div>

      <button
        className={`button ${code.length === 6 ? 'red-background' : 'grey-background'}`}
        disabled={code.length === 6 ? false : true}
        onClick={handleSubmit}
      >
        Verify
      </button>
    </div>
  );
}

export default Verification;
