import React, { useState, useEffect } from 'react';
import axios from 'axios';
import {encrypttoHEX} from './encodedecode.js';
function FeedConstruction(props) {
  // const test = encrypttoHEX(JSON.stringify("test"));
  const [formData, setFormData] = useState({
    Name: '',
    SessionLink: '',
    SessionStartTime: '',
    SessionEndTime: '',
    MaxStarted: '',
    MaxCompleted: '',
  });
  const [assets, setAssets] = useState([]);
  const [assetPositions, setAssetPositions] = useState({});
  const [feedCreationSuccess, setFeedCreationSuccess] = useState(false); // New state variable
  const [feedBrands, setFeedBrands] = useState([]);
  const [brandReportURLS, setBrandReportURLS] = useState([]);
  const [feedURL, setFeedURL] = useState("");
  const [feedID, setFeedID] = useState("");
  useEffect(() => {
    axios
      .get('https://ambassco-staging.feeltiptop.com/api/getAssets', {
        headers: {
          'Authorization': 'Bearer ' + props.token
        }})
      .then((response) => {
        setAssets(response.data);
      })
      .catch((error) => {
        console.error(error);
      });
  }, []);
  const handleSelectChange = (e, assetID, brandID, brandName) => {
    const value = parseInt(e.target.value);
    const newAssetPositions = { ...assetPositions };
    const isBrandInArray = feedBrands.includes(brandID);
    if (value !== 0) {
      if (Object.values(newAssetPositions).includes(value)) {
        alert(
          `Duplicate position selected: ${value}. Each asset must have a unique position.`
        );
        return;
      }
      newAssetPositions[assetID] = value;
      if (!isBrandInArray) {
        setFeedBrands([...feedBrands, {brandID:brandID, brandName : brandName}])
      }
    } else {
      delete newAssetPositions[assetID];
      if (isBrandInArray) {
        setFeedBrands(feedBrands.filter((item) => item.id !== brandID));
      }
    }
    setAssetPositions(newAssetPositions);
  };
  const handleInputChange = (e, field) => {
    setFormData((prevFormData) => ({
      ...prevFormData,
      [field]: e.target.value,
    }));
  };
  const handleSubmit = (e) => {
    e.preventDefault();
    const now = new Date();
    const oneHourLater = new Date(now.getTime() + 60 * 60 * 1000);
    const formatDateTime = (date) =>
      date.toISOString().slice(0, 19).replace('T', ' ');
    const submissionData = {
      ...formData,
      SessionStartTime: formData.SessionStartTime
        ? formatDateTime(new Date(formData.SessionStartTime))
        : formatDateTime(now),
      SessionEndTime: formData.SessionEndTime
        ? formatDateTime(new Date(formData.SessionEndTime))
        : formatDateTime(oneHourLater),
      SessionLink: feedURL
    };
    console.log(submissionData)
    /* for testing urls without adding to DB
    let tfeedId = 1;
    setBrandReportURLS(feedBrands.map((br) => {let reporturl = createObfuscatedReportURL(br.brandID, tfeedId) ;return {id: br.brandID, name :br.brandName, reporturl:reporturl}})
    )
    setFeedURL(createObfuscatedFeedURL(tfeedId))
    setFeedID(tfeedId)
    // this does not work. It only adds the last one.
    // feedBrands.forEach( br =>{
    //   let reporturl = createObfuscatedReportURL(br.brandID, feedId)
    //   setbrandReportURLS([...brandReportURLS, {id: br.brandID, name :br.brandName, reporturl:reporturl}])
    //   console.log(brandReportURLS);
    //   }
    // );
    setFeedCreationSuccess(true);
    return;
    */
    axios
      .post('https://ambassco-staging.feeltiptop.com/api/addFeed', submissionData, {
        headers: {
          'Authorization': 'Bearer ' + props.token
        }})
      .then((response) => {
        const tfeedId = response.data.FeedID;
        setFeedID(tfeedId);
        createObfuscatedReportURL();
        addFeedAssets(tfeedId);
        // addFeedUser(tfeedId);
        setBrandReportURLS(feedBrands.map((br) => {let reporturl = createObfuscatedReportURL(br.brandID, tfeedId) ;return {id: br.brandID, name :br.brandName, reporturl:reporturl}})
        )
        setFeedURL(createObfuscatedFeedURL(tfeedId))
        setFeedCreationSuccess(true);
        // console.log(encrypttoHEX(JSON.stringify("test")));
        axios.post('https://ambassco-staging.feeltiptop.com/api/addFeedURL', {
          FeedID: tfeedId,
          SessionLink: "https://ambassco-staging.feeltiptop.com/"+ createObfuscatedFeedURL(tfeedId)
        }, {
          headers: {
            'Authorization': 'Bearer ' + props.token
          }});
            })
      .catch((error) => {
        console.error('Error adding feed:', error);
        setFeedCreationSuccess(false);
      });


  };
  const addFeedUser = (feedId) => {
    axios
      .post('https://ambassco-staging.feeltiptop.com/api/addFeedUser', {
        feedId,
      }, {
        headers: {
          'Authorization': 'Bearer ' + props.token
        }})
      .then((response) => {
        console.log('feed user added:', response.data);
        // console.log(encrypttoHEX(JSON.stringify("test")));
      })
      .catch((error) => {
        console.error('Error adding feed user:', error);
      });
  };
  const addFeedAssets = (feedId) => {
    axios
      .post('https://ambassco-staging.feeltiptop.com/api/addFeedAssets', {
        feedId,
        assets: assetPositions,
      }, {
        headers: {
          'Authorization': 'Bearer ' + props.token
        }})
      .then((response) => {
        console.log('Feed assets added:', response.data);
      })
      .catch((error) => {
        console.error('Error adding feed assets:', error);
      });
  };
  const createObfuscatedReportURL = (brandID, feedID) => {
    const encryptSTR = JSON.stringify({feedID:feedID, brandID:brandID});
    const URL = "Report?p=" + encrypttoHEX(encryptSTR);
    console.log("Report URL: " + URL);
    return URL;
  }
  const createObfuscatedFeedURL = (feedID) => {
    const encryptSTR = JSON.stringify({feedID:feedID});
    const URL = "Login?p=" + encrypttoHEX(encryptSTR);
    const ambassco_url = "https://ambassco-staging.feeltiptop.com/" + URL

    console.log("Login URL: " + URL);
    return URL;
  }
  return (
    <div>
      {/* Render assets fetched from the API */}
      <div>
        {assets.map((asset) => (
          <div key={asset.AssetID} className="asset-item">
            <span className="asset-label">{`${asset.AssetName}: "${asset.BrandName}" "${asset.Caption}"`}</span>
            <select
              name={`assetPosition${asset.AssetID}`}
              className="position-select"
              onChange={(e) => handleSelectChange(e, asset.AssetID, asset.BrandID, asset.BrandName)}
            >
              <option value="0">0 (Not in feed)</option>
              {Array.from({ length: 100 }, (_, i) => (
                <option key={i} value={`${i + 1}`}>
                  {i + 1}
                </option>
              ))}
            </select>
          </div>
        ))}
      </div>
      {/* Input fields for user input */}
      <form onSubmit={handleSubmit}>
        <label htmlFor="Name">Name:</label>
        <input
          type="text"
          id="Name"
          name="Name"
          value={formData.Name}
          onChange={(e) => handleInputChange(e, 'Name')}
        />
        <br />
        {/* <label htmlFor="SessionLink">Session Link:</label>
        <input
          type="text"
          id="SessionLink"
          name="SessionLink"
          value={formData.SessionLink}
          onChange={(e) => handleInputChange(e, 'SessionLink')}
        />
        <br /> */}
        <label htmlFor="SessionStartTime">Session Start Time:</label>
        <input
          type="datetime-local"
          id="SessionStartTime"
          name="SessionStartTime"
          value={formData.SessionStartTime}
          onChange={(e) => handleInputChange(e, 'SessionStartTime')}
        />
        <br />
        <label htmlFor="SessionEndTime">Session End Time:</label>
        <input
          type="datetime-local"
          id="SessionEndTime"
          name="SessionEndTime"
          value={formData.SessionEndTime}
          onChange={(e) => handleInputChange(e, 'SessionEndTime')}
        />
        <br />
        <label htmlFor="MaxStarted">Max Started:</label>
        <input
          type="number"
          id="MaxStarted"
          name="MaxStarted"
          value={formData.MaxStarted}
          onChange={(e) => handleInputChange(e, 'MaxStarted')}
        />
        <br />
        <label htmlFor="MaxCompleted">Max Completed:</label>
        <input
          type="number"
          id="MaxCompleted"
          name="MaxCompleted"
          value={formData.MaxCompleted}
          onChange={(e) => handleInputChange(e, 'MaxCompleted')}
        />
        <br />
        <button type="submit" className="construct-button">
          Construct Feed
        </button>
      </form>
      {feedCreationSuccess && (
        <div>
            <div className="confirmation-message">
              Feed Created Successfully!
            </div>
            <div className="feedurls">
              <h3>Feed Url</h3>
              https://ambassco-staging.feeltiptop.com/{feedURL}
              <br/>
              <h3>Brand Report Urls:</h3>
                {
                  <ul>
                  {brandReportURLS.map((branddetails) => <li key={branddetails.id}>{ branddetails.name} - https://ambassco-staging.feeltiptop.com/{branddetails.reporturl}</li> )
                }
                </ul>
                }
              <br/>
              <h3>Feed Data Dump:</h3>
              https://ambassco-staging.feeltiptop.com/FeedSessionExport?feedid={feedID}
              <br/>
              <h3>Ambassador's Data Dump:</h3>
              https://ambassco-staging.feeltiptop.com/AmbassadorProfiles
            </div>
        </div>
      )}
    </div>
  );
}
export default FeedConstruction;